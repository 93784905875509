(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("gsap"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("Draggable"), require("sbDataLayer"));
	else if(typeof define === 'function' && define.amd)
		define("sb-quick-betslip-block", ["React", "mobx", "mobxReact", "gsap", "sbInternalMsgBus", "sbRespBlockLib", "Draggable", "sbDataLayer"], factory);
	else if(typeof exports === 'object')
		exports["sb-quick-betslip-block"] = factory(require("React"), require("mobx"), require("mobxReact"), require("gsap"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("Draggable"), require("sbDataLayer"));
	else
		root["sb-quick-betslip-block"] = factory(root["React"], root["mobx"], root["mobxReact"], root["gsap"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["Draggable"], root["sbDataLayer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__203__) {
return 