import * as React from "react";

type TouchHandlers = {
    onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
    onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => void
}

export function DragDownController(endCb: Function): TouchHandlers {
    let yStart = 0;
    let yDiff = 0;

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        yStart = e.changedTouches[0].clientY;
    }

    const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        yDiff = e.changedTouches[0].clientY - yStart;
        if (yDiff > 50) {
            endCb();
        }
    }

    return { onTouchStart, onTouchEnd };
}